import { UserAgentApplication } from "msal";

export const config = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    clientId: process.env.REACT_APP_APPLICATION_URI!, // application ID
    redirectUri: process.env.REACT_APP_REDIRECT_URI!,
  },
  system: {
    loadFrameTimeout: 10000,
  },
};

export const myMsal = new UserAgentApplication(config);
const isAadCallback = (): boolean => {
  return myMsal.urlContainsHash(window.location.hash || "");
};
export const authenticate = () => {
  if (window.location.pathname === "/logout") {
    return new Promise((resolve) => {
      resolve(true);
    });
  }
  return new Promise((resolve, reject) => {
    const account = myMsal.getAccount();
    if (!isAadCallback() && !account) {
      let request = {
        scopes: ["user.read"], // once permission granted, change the scope as Files.Read.All
        // scopes: ["Sites.ReadWrite.All"],
        prompt: "select_account",
      };
      //getAccessToken();
      myMsal.loginRedirect(request);
      //getAccessToken();
      return;
    } else {
      const rawIdToken = sessionStorage.getItem("msal.idtoken");
      if (rawIdToken) {
        const decodedToken = JSON.parse(atob(rawIdToken.split(".")[1]));
        resolve({
          rawIdToken,
          preferredName: decodedToken.preferred_username,
          name: decodedToken.name,
        });
      }
    }

    myMsal.handleRedirectCallback((error, response) => {
      if (error) {
        reject(error);
      } else {
        const idToken = response ? response.idToken : "";
        resolve(idToken);
      }
    });
  });
};

export const getUserId = () => {
  const rawIdToken = sessionStorage.getItem("msal.idtoken");
  if (rawIdToken) {
    return (JSON.parse(atob(rawIdToken.split(".")[1]))['oid']);
  }
}

const getTenantId = () => {
  const jwt = sessionStorage.getItem("msal.idtoken");
  if (jwt) {
    return JSON.parse(atob(jwt.split(".")[1]))["tid"];
  }
};

export const isTenantAllowed = () => {
  const tenantId = getTenantId();
  
  if(process.env.REACT_APP_ALLOWED_TENANTS?.includes(tenantId)){
    return true;
  }
  return false;
}

export const getAccessToken = async () => {
  if (window.location.pathname === "/logout") {
    return new Promise((resolve) => {
      resolve(true);
    });
  }
  const account = myMsal.getAccount();
  return new Promise((resolve, reject) => {
    myMsal
      .acquireTokenSilent({
        // scopes: ["api://a48c4b4b-62e6-4469-bbff-0bc666123b9c/Software.Download"]
        scopes: [
          `${process.env.REACT_APP_APPLICATION_BASE_URI}/Software.Upload`,
        ],
        account: account,
      })
      .then((result: any) => {
        resolve(result.accessToken);
        sessionStorage.setItem("msal.idtoken", result.accessToken);
      })
      .catch((error: any) => {
        console.log("The error is : ", error);
        reject(null);
      });
  });
};
