export const deviceTypeOptions = [
    {key:'ipphone', text: 'Phones (IpPhone)'},
    {key:'kingston', text: 'Teams Displays (Kingston)'},
    {key:'norden', text: 'Collaboration Bars (Norden)'},
    {key:'nordenConsole', text: 'Touch Consoles (Norden Console)'},
    {key:'lcp', text: 'Low Cost Phones (Lcp)'},
    {key:'panel', text: 'Panels (Panel)'},
]
export const environmentOptions = [
    {key:'Test', text: 'Test'},
    {key:'DogFood', text: 'Dog Food'}
]
export const modelOptions = [
    {key:'uc2', text: 'UC-2'},
    {key:'t55a', text: 'T55A'}
]
export const typeOptions = [
    {key:'firmware', text: 'Firmware Update'},
    {key:'teams', text: 'Teams Update'},
    {key:'companyportal', text: 'Company Portal Update'},
    {key:'adminagent', text: 'Admin Agent Update'},
    {key:'microsoftintuneapp', text: 'Microsoft Intune Update'},
    {key:'authenticatorapp', text: 'Authenticator Update'},
]