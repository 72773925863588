import { primitive, serializable } from 'serializr';

export class GetSoftwareDetailsRequestModel {
  @serializable(primitive())
  public deviceType: string | undefined | number;

  @serializable(primitive())
  public model: string | undefined;

  @serializable(primitive())
  public manufacturer: string | undefined;

  @serializable(primitive())
  public componentType: string | undefined | number;

  @serializable(primitive())
  public versionCode: string | undefined;

  @serializable(primitive())
  public versionName: string | undefined;

  @serializable(primitive())
  public current: boolean | undefined;
}

export class PromoteSoftwareRequestModel {
  @serializable(primitive())
  public id: string | undefined;

  @serializable(primitive())
  public componentType: string | undefined;

  @serializable(primitive())
  public releaseStage: string | undefined;
}
