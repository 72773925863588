import { PrimaryButton } from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { authenticate, getAccessToken } from "./auth";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const showLandingPage = () => {
  const onDialogOK = () => {
    authenticate()
      .then(() => {
        getAccessToken().then((token: any) => {
          sessionStorage.setItem("msal.idtoken", token);
          ReactDOM.render(
            <React.StrictMode>
              <App />
            </React.StrictMode>,
            document.getElementById("root")
          );
        });
      })
      .catch((error) => {
        console.log("Login Failed : ", error);
      });
  };

  if (sessionStorage.length > 0) {
    return ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  }

  return ReactDOM.render(
    <React.StrictMode>
      <div className="loginPageContainer">
        <div className="loginContainer">
          <h1 className="loginHeading">Software Upload Tool</h1>
          <PrimaryButton text="Login" onClick={onDialogOK} />
          <span className="loginTermsConditionText">
            By clicking on Login button, you accept our{" "}
            <a rel="noopener noreferrer" target="_blank" href="https://www.bing.com">
              Terms and Conditions
            </a>.
          </span>
        </div>
      </div>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

initializeIcons();
showLandingPage();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
