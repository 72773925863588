import { DetailsList, IColumn, ISelection, SelectionMode, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import './details-list.scss';
interface Iprops
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  columns?: IColumn[];
  items: any[];
  onRenderItemColumn?: (item: any, index: any, column: any) => void;
  onScroll?: any;
  isTableDataLoading: boolean;
  selection?: ISelection;
  enableSelection?: boolean;
}
export class List extends React.Component<
  Iprops,
  {
    onListScroll?: (
      vOffset: number,
      hOffset: number,
      atBottom: boolean,
      event: React.UIEvent<HTMLDivElement>,
    ) => void;
    listHeight: number;
    atBottom: boolean;
  }
> {
  private rootNode!: HTMLElement;
  private readonly getRootRef = (node: HTMLDivElement) => {
    if (node !== null) {
      this.rootNode = node;
    }
  };
  private readonly handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const vOffset = this.rootNode.scrollTop;
    const scrollHeight = this.rootNode.scrollHeight;
    const height = this.rootNode.clientHeight;
    const atBottom = Math.abs(Math.round(scrollHeight - vOffset) - height) <= 1;
    if (atBottom && !this.props.isTableDataLoading) {
      this.props.onScroll();
    }
  };
  render(): React.ReactNode {
    return (
      <React.Fragment>
        <div
          ref={this.getRootRef}
          className={'tableDiv'}
          onScroll={this.handleScroll}
        >
          {this.props.isTableDataLoading &&
          <Spinner
          label="Loading More Data..." 
          ariaLive="assertive" 
          labelPosition="top"
          size={SpinnerSize.medium}
          className='tableSpinner'/>
          }

          <DetailsList
            items={this.props.items}
            columns={this.props.columns}
            onRenderItemColumn={this.props.onRenderItemColumn}
            disableSelectionZone={!this.props.enableSelection}
            selectionMode={this.props.enableSelection ? SelectionMode.single : SelectionMode.none}
            selection={this.props.selection}
            selectionPreservedOnEmptyClick={true}
            setKey="set"
          />
        </div>
      </React.Fragment>
    );
  }
}
