import { useState, useMemo, useEffect } from 'react';
import {
  DefaultButton,
  Dialog,
  DialogType,
  DialogFooter,
  Dropdown,
  IDropdownStyles,
  TextField,
  PrimaryButton,
  Checkbox,
  Spinner,
} from '@fluentui/react';
import { typeOptions, deviceTypeOptions } from '../FirmwareUpdate/constants';
import './index.scss';
import { currentStatus, ringTableHeaderMap, tableHeaderMap } from './constants';
import customAxios from '../../customAxios';
import MessageBar from '../../common/messageBar';
import {
  GetSoftwareDetailsRequestModel,
  PromoteSoftwareRequestModel,
} from './promote-software-model';
import { List } from '../List/details-list';
import { isTenantAllowed } from '../../auth';

interface SoftwareResponseModel {
  deviceType: string;
  manufacturer: string;
  model: string;
  componentType: string;
  fileName: string;
  versionCode: number;
  versionName: string;
  fileUrl: string;
  description: string;
  releaseNotes: string;
  dependencyInfo?: {
    firmware: number;
    oem: number;
  } | null;
  releaseStages?: {
    DogFood?:
      | {
          id: string;
          publishedBy: string;
          publishDate: string;
          current: boolean;
        }
      | null
      | undefined;
    MSIT?:
      | {
          id: string;
          publishedBy: string;
          publishDate: string;
          current: boolean;
        }
      | null
      | undefined;
    TAP?:
      | {
          id: string;
          publishedBy: string;
          publishDate: string;
          current: boolean;
        }
      | null
      | undefined;
    PROD:
      | {
          id: string;
          publishedBy: string;
          publishDate: string;
          current: boolean;
        }
      | null
      | undefined;
  } | null;
}

const PromoteSoftware = () => {
  const [model, setModel] = useState<string | undefined>('');
  const [make, setMake] = useState<string | undefined>('');
  const [verCode, setVerCode] = useState<string | undefined>('');
  const [verName, setVerName] = useState<string | undefined>('');
  const [type, setType] = useState<string | undefined | number>();
  const [deviceType, setDeviceType] = useState<string | undefined | number>();
  const [continuationToken, setContinuationToken] = useState<string>('');
  const [isFetchDataCalled, setIsFetchDataCalled] = useState<boolean>(false);
  const [spinner, showSpinner] = useState(false);
  const [dialog, showDialog] = useState(false);
  const [isPromoteDialog, setIsPromoteDialog] = useState(false);
  const [promoteSoftwareRequest, setPromoteSoftwareRequest] =
    useState<PromoteSoftwareRequestModel>();
  const [softwareResponseList, setsoftwareResponseList] = useState<
    SoftwareResponseModel[]
  >([]);
  const [isTableDataLoading,setIsTableDataLoading] = useState<boolean>(false)

  const [dialogContent, setDialogContent] = useState({
    type: DialogType.normal,
    title: 'Promote Software?',
    closeButtonAriaLabel: 'Close',
    subText: '',
    env: '',
  });

  const initialMessageBarContent = {
    showMessageBar: false,
    message: '',
    type: 0,
  };

  const [messageBarContent, setMessageBarContent] = useState(
    initialMessageBarContent,
  );

  const resetMessageBarContent = () =>
    setMessageBarContent({
      ...messageBarContent,
      ...initialMessageBarContent,
    });

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

  const dialogStyles = { main: { maxWidth: 450 } };

  const labelId: string = 'label';
  const subTextId: string = 'subTextLabel';

  const onCheckBoxChange = (
    software: SoftwareResponseModel,
    environment: string
  ) => {
    setPromoteSoftwareRequest({
      id: getPromoteRequestId(software, environment),
      componentType: software.componentType,
      releaseStage: environment,
    });
    setDialogContent({
      ...dialogContent,
      title: 'Promote Software?',
      subText: `Do you want to promote software to ${environment}?`,
      env: environment,
    });
    setIsPromoteDialog(true);
    showDialog(true);
  };

  const getPromoteRequestId = (
    software: SoftwareResponseModel,
    environment: string
  ) => {
    switch (environment) {
      case 'MSIT':
        return software.releaseStages?.DogFood?.id;
      case 'TAP':
          return software.releaseStages?.MSIT?.id;
      case 'PROD':
        return software.releaseStages?.TAP?.id;
    }
  };

  const isFetchDetailsDisabled = () => {
    return !(make && model && type && verCode && deviceType);
  };

  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: undefined,
    }),
    [labelId, subTextId],
  );

  const getSoftwareDetails = async () => {
    const requestBody = new GetSoftwareDetailsRequestModel();
    if (make !== undefined && make.length > 0) {
      requestBody.manufacturer = make;
    }
    if (model !== undefined && model.length > 0) {
      requestBody.model = model;
    }
    if (type !== undefined) {
      requestBody.componentType = type;
    }
    if (verCode !== undefined && verCode.length > 0) {
      requestBody.versionCode = verCode;
    }
    if (verName !== undefined && verName.length > 0) {
      requestBody.versionName = verName;
    }
    if (deviceType !== undefined) {
      requestBody.deviceType = deviceType;
    }
    const payload = {
      filterJson: {
        ...requestBody,
      },
      continuationToken: continuationToken,
    };
    await setIsTableDataLoading(true)
    await customAxios
      .get('softwarePackages', { params: payload })
      .then((response) => {
        console.log(response);
        showSpinner(false);
        setsoftwareResponseList([
          ...softwareResponseList,
          ...response.data['items'],
        ]);
        setContinuationToken(response.data['continuationToken']);
      })
      .catch(() => {
        showSpinner(false);
        setDialogContent({
          ...dialogContent,
          title: 'Failed',
          subText: 'Unable to fetch details, Please try again',
        });
        showDialog(true);
        setIsPromoteDialog(false);
      });
      setIsTableDataLoading(false)
  };

  const disableCheckBox = (env: string, software: any) => {
    if (!process.env.REACT_APP_ISPROMOTION_CHECK_REQUIRED) {
      return false;
    }
    switch (env) {
      case 'DogFood': {
        return (
          !!software.releaseStages?.DogFood ||
          !!software.releaseStages?.MSIT ||
          !!software.releaseStages?.TAP ||
          !!software.releaseStages?.PROD
        );
      }
      case 'MSIT': {
        return (
          !software.releaseStages?.DogFood || 
          !!software.releaseStages?.MSIT ||
          !!software.releaseStages?.TAP ||
          !!software.releaseStages?.PROD
        );
      }
      case 'TAP': {
        return (
          !software.releaseStages?.MSIT || 
          !!software.releaseStages?.TAP ||
          !!software.releaseStages?.PROD
        );
      }
      case 'PROD': {
        return (
          !software.releaseStages?.TAP || 
          !!software.releaseStages?.PROD
          )
      }
      default: {
        break;
      }
    }
    return false;
  };

  const promoteSoftwareAction = async (env: string) => {
    setIsPromoteDialog(false);
    showSpinner(true);
    await customAxios
      .patch('softwarePackages/promote', promoteSoftwareRequest)
      .then((response) => {
        console.log(response);
        showSpinner(false);

        setDialogContent({
          ...dialogContent,
          title: 'Success',
          subText: `Request raised for Promoting Software to ${env}`,
          env: env,
        });
      })
      .catch(() => {
        showSpinner(false);

        setDialogContent({
          ...dialogContent,
          title: 'Failed',
          subText: `Unable to raise Promote Software request to ${env}`,
          env: env,
        });
      });
    showDialog(true);
  };

  const onDialogOK = () => {
    promoteSoftwareAction(dialogContent.env);
    showDialog(false);
  };

  const onDialogCancel = () => {
    showDialog(false);
  };

  useEffect(() => {
    if (isFetchDataCalled) {
      getSoftwareDetails();
      setIsFetchDataCalled(false);
    }
  }, [softwareResponseList]);

  const fetchData = () => {
    setsoftwareResponseList([]);
    setContinuationToken('');
    showSpinner(true);
    setIsFetchDataCalled(true);
  };

  const columns = () => {
    const columns: any[] = [];
    const tableHeaderKeys = Object.keys(tableHeaderMap);
    tableHeaderKeys.forEach((item, index) => {
      columns.push({
        key: 'column' + index,
        name: tableHeaderMap[item],
        fieldName: item,
        minWidth: 120,
        isResizable: true,
      });
    });
    const ringTableHeaderKeys = Object.keys(ringTableHeaderMap);
    ringTableHeaderKeys.forEach((item, index) => {
    if(process.env.REACT_APP_ALLOWED_RINGS?.includes(ringTableHeaderMap[item])){
      columns.push({
        key: 'column' + index,
        name: ringTableHeaderMap[item],
        fieldName: item,
        minWidth: 120,
        isResizable: true,
      });
    }
  });
    return columns;
  };
  const checkIfTenantAllowed = isTenantAllowed();
  const renderItems = () => {
    if (softwareResponseList.length > 0) {
      
      let items: any[] = [];
      softwareResponseList.forEach((item: SoftwareResponseModel, index) => {
        items.push({
          deviceType: item.deviceType,
          manufacturer: item.manufacturer,
          model: item.model,
          componentType: item.componentType,
          versionCode: item.versionCode,
          versionName: item.versionName,
          dogFood: item.releaseStages?.DogFood ? (
            item.releaseStages.DogFood.current ? (
              currentStatus.active
            ) : (
              currentStatus.inActive
            )
          ) : (
            <div className='checkbox-container'>
              <Checkbox
                checked={!!item.releaseStages?.DogFood}
                onChange={() => onCheckBoxChange(item, 'DogFood')}
                disabled={disableCheckBox('DogFood', item)}
              />
            </div>
          ),
          msit: item.releaseStages?.MSIT ? (
            item.releaseStages.MSIT.current ? (
              currentStatus.active
            ) : (
              currentStatus.inActive
            )
          ) : (
            <div className='checkbox-container'>
              <Checkbox
                checked={!!item.releaseStages?.MSIT}
                onChange={() => onCheckBoxChange(item, 'MSIT')}
                disabled={!checkIfTenantAllowed || disableCheckBox('MSIT', item)}
              />
            </div>
          ),
          tap: item.releaseStages?.TAP ? (
            item.releaseStages.TAP.current ? (
              currentStatus.active
            ) : (
              currentStatus.inActive
            )
          ) : (
            <div className='checkbox-container'>
              <Checkbox
                checked={!!item.releaseStages?.TAP}
                onChange={() => onCheckBoxChange(item, 'TAP')}
                disabled={!checkIfTenantAllowed || disableCheckBox('TAP', item)}
              />
            </div>
          ),
          prod: item.releaseStages?.PROD ? (
            item.releaseStages.PROD.current ? (
              currentStatus.active
            ) : (
              currentStatus.inActive
            )
          ) : (
            <div className='checkbox-container'>
              <Checkbox
                checked={!!item.releaseStages?.PROD}
                onChange={() => onCheckBoxChange(item, 'PROD')}
                disabled={!checkIfTenantAllowed || disableCheckBox('PROD', item)}
              />
            </div>
          ),
        });
      });
      return items;
    } else return [];
  };

  const loadMoreData = () => {
    if (continuationToken && continuationToken !== '') {
      getSoftwareDetails();
    }
  };
  const pageHeading = checkIfTenantAllowed ? 'Promote Software' : 'View Software';
  return (
    <div>
      {messageBarContent.showMessageBar && (
        <MessageBar
          message={messageBarContent.message}
          messageBarType={messageBarContent.type}
          onDismiss={resetMessageBarContent}
        />
      )}
      <div className='promote-software-container'>
        {dialog && (
          <Dialog
            hidden={!dialog}
            onDismiss={() => showDialog(false)}
            dialogContentProps={dialogContent}
            modalProps={modalProps}
          >
            {isPromoteDialog ? (
              <DialogFooter>
                <PrimaryButton onClick={onDialogOK} text='Promote' />
                <DefaultButton onClick={onDialogCancel} text='Cancel' />
              </DialogFooter>
            ) : (
              <DialogFooter>
                <PrimaryButton onClick={onDialogCancel} text='Ok' />
              </DialogFooter>
            )}
          </Dialog>
        )}
        <h2>{pageHeading}</h2>
        <h3>Get software version</h3>
        <div className='inner-container'>
          <TextField
            className='textfield-inner'
            label='Make'
            placeholder='Enter the Make'
            onChange={(_e, value) => setMake(value)}
            value={make}
            required
          />
          <TextField
            className='textfield-inner'
            label='Model'
            placeholder='Enter the Model'
            onChange={(_e, value) => setModel(value)}
            value={model}
            required
          />
          <Dropdown
            label='Type'
            placeholder='Select a Type'
            options={typeOptions}
            styles={dropdownStyles}
            onChange={(_e, option) => setType(option?.key)}
            required
          />
          <TextField
            className='textfield-inner'
            label='Version Code'
            placeholder='Enter the Version Code'
            onChange={(_e, value) => setVerCode(value)}
            value={verCode}
            required
          />
          {/* <TextField
            className='textfield-inner'
            label='Version Name'
            placeholder='Enter the Version Name'
            onChange={(_e, value) => setVerName(value)}
            value={verName}
            required
          /> */}
          <Dropdown
            label='Device Type'
            placeholder='Select a Device Type'
            options={deviceTypeOptions}
            styles={dropdownStyles}
            onChange={(_e, option) => setDeviceType(option?.key)}
            required
          />
        </div>
        <PrimaryButton
          className='buttonContainer'
          disabled={isFetchDetailsDisabled()}
          text='Fetch details'
          onClick={fetchData}
        />
        {spinner ? (
          <div className='spinner'>
            <Spinner />
          </div>
        ) : (
          <div>
            <h3 className='results-container'>Result</h3>
            {/* <table>
            <thead>
              <tr>
                {tableHeaderOptions.map((option) => (
                  <th>{option}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {softwareResponseList &&
                softwareResponseList.map((software) => {
                  return (
                    <tr>
                      <td>{software.deviceType}</td>
                      <td>{software.manufacturer}</td>
                      <td>{software.model}</td>
                      <td>{software.componentType}</td>
                      <td>{software.versionName}</td>
                      <td>{software.versionCode}</td>
                      <td>
                        {!!software.releaseStages?.DogFood ? (
                          software.releaseStages.DogFood.current ? (
                            'Active'
                          ) : (
                            'Inactive'
                          )
                        ) : (
                          <div className='checkbox-container'>
                            <Checkbox
                              checked={!!software.releaseStages?.DogFood}
                              onChange={()=>onCheckBoxChange(software,'DogFood')}
                              disabled={disableCheckBox('DogFood',software)}
                            />
                          </div>
                        )}
                      </td>
                      <td>
                        {!!software.releaseStages?.MSIT ? (
                          software.releaseStages.MSIT.current ? (
                            'Active'
                          ) : (
                            'Inactive'
                          )
                        ) : (
                          <div className='checkbox-container'>
                            <Checkbox
                              checked={!!software.releaseStages?.MSIT}
                              onChange={()=>onCheckBoxChange(software,'MSIT')}
                              disabled={disableCheckBox('MSIT',software)}
                            />
                          </div>
                        )}
                      </td>
                      <td>
                        {!!software.releaseStages?.TAP ? (
                          software.releaseStages.TAP.current ? (
                            'Active'
                          ) : (
                            'Inactive'
                          )
                        ) : (
                          <div className='checkbox-container'>
                            <Checkbox
                              checked={!!software.releaseStages?.TAP}
                              onChange={()=>onCheckBoxChange(software,'TAP')}
                              disabled={disableCheckBox('TAP',software)}
                            />
                          </div>
                        )}
                      </td>

                      <td>
                        {!!software.releaseStages?.PROD ? (
                          software.releaseStages.PROD.current ? (
                            'Active'
                          ) : (
                            'Inactive'
                          )
                        ) : (
                          <div className='checkbox-container'>
                            <Checkbox
                              checked={!!software.releaseStages?.PROD}
                              onChange={()=>onCheckBoxChange(software,'PROD')}
                              disabled={disableCheckBox('PROD',software)}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table> */}
            <List
              items={renderItems()}
              columns={columns()}
              onScroll={() => {
                loadMoreData();
              }}
              isTableDataLoading={isTableDataLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoteSoftware;
