export const requestStatusOptions = [
  { key: 'created', text: 'In Progress' },
  { key: 'approved', text: 'Approved' },
  { key: 'rejected', text: 'Rejected' },
  { key: 'errored', text: 'Errored' },
];

export const releaseStageOptions: { [key: string]: string } = {
  '1': 'DogFood',
  '2': 'MSIT',
  '3': 'TAP',
  '4': 'PROD',
};

export const status: { [key: string]: string } = {
  created: 'Created',
  approved: 'Approved',
  rejected: 'Rejected',
  errored: 'Errored',
};

export const tableHeaderMap: { [key: string]: string } = {
  name: 'Name',
  manufacturer: 'Manufacturer',
  model: 'Model',
  componentType: 'Component Type',
  versionCode: 'Version Code',
  releaseStage: 'Release Stage',
  createdByUserName: 'Created By',
  modifiedByUserName: 'Modified By',
  status: 'Status',
  errorMessage: 'Error Message'
};

export const tableHeaderOptions = [
  'Name',
  'Manufacturer',
  'Model',
  'Component Type',
  'Version Code',
  'Release Stage',
  'Created By',
  'Updated By',
  'Status',
];
