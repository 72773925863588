import axios from 'axios';
import {getAccessToken} from './auth';

const customAxios = axios.create({
    // baseURL: 'https://localhost/api/v2/',
    baseURL: `${process.env.REACT_APP_DEVICE_MGMT_SERVICE_URI}/api/v2/`,
    // timeout: 10000,
    // headers: { 'api-key': ''}
});

const requestHandler = async (request: any) => {
    const jwt = sessionStorage.getItem('msal.idtoken');
    if(jwt){
        const existingExp = JSON.parse(atob(jwt.split(".")[1]))['exp']
        if(Date.now() > existingExp * 1000){
            await getAccessToken().then((resp) => {
                console.log(resp);
              });
            }
    }
    // const auth_token = sessionStorage.getItem('msal.idtoken');
    const auth_token = sessionStorage.getItem('msal.idtoken');
    request.headers.Authorization = `Bearer ${auth_token}`;
    request.headers['Access-Control-Allow-Origin'] = '*';
    request.headers.withCredentials = true;
    request.headers.mode = 'no-cors';
    return request;
};

const responseHandler = (response: any) => {
    if(response.statusCode === '401') {
        // redirect to a 401 page
        window.location.href = '/401';

        // refetch access token and set to cookie
        //have a retry mechanism after that
    }
    return response;
}

const errorHandler = (error: any) => {
    return Promise.reject(error);
}

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default customAxios;