import {MessageBar as FluentMessageBar, Link} from '@fluentui/react';
import { useEffect } from 'react';
import './index.scss';

const MessageBar = (props: any) => {
    useEffect(()=>{
        setTimeout(()=> props.onDismiss(), 3000)
    }, [props])
    return (
        <FluentMessageBar
            className='sticky'
            messageBarType={props.messageBarType}
            isMultiline={props.isMultiline || false}
            onDismiss={props.onDismiss}
        >
            {props.message}
            {props.hasLink && (
            <Link href={props.hasLink.href} target="_blank" underline>
                {props.hasLink.linkText}
            </Link>
            )}
        </FluentMessageBar>)
}

export default MessageBar;