export const jobTableHeaderMap: { [key: string]: string } = {
  jobId: "ID",
  deviceType: "Device Type",
  manufacturer: "Manufacturer",
  model: "Model",
  componentType: "Component Type",
  jobStatus: "Status",
  jobCreatedAt: "Created At",
};


export const taskStateTableHeaderMap: { [key: string]: string } = {
  taskName: 'Name',
  taskCreatedAt: 'Created At',
  taskStartedAt: 'Started At',
  taskCompletedAt: 'Completed At',
  taskStatus: 'Status',
  message: 'Message',
};


export const requestStatusOptions = [
  { key: 'Ready', text: 'Ready' },
  { key: 'InProgress', text: 'In Progress' },
  { key: 'Completed', text: 'Completed' },
  { key: 'Failed', text: 'Failed' },
  { key: 'TimedOut', text: 'Timed Out' },
];

export const jobStatus: { [key: string]: string } = {
  Ready: 'Ready',
  InProgress: 'In Progress',
  Completed: 'Completed',
  Failed: 'Failed',
};