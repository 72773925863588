import { withRouter } from "react-router-dom";
import { Nav, INavLinkGroup, INavLink, DefaultButton, IContextualMenuProps } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React from "react";
import './navBar.scss';
import { isTenantAllowed } from "../../auth";

function WithNavigation(props: any) {
    React.useEffect(() => {
      props.history.replace("/");
    }, []);
  const checkIfTenantAllowed = isTenantAllowed();
  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: "Upload software",
          url: "/",
          key: "key1",
        },
        {
          name: "Software Upload Job Management",
          url: "/softwareUploadJobManagement",
          key: "key2",
        },
        {
          name: checkIfTenantAllowed ? "Promote software" : "View Software",
          url: "/promoteSoftware",
          key: "key3",
        },
      ],
    },
  ];

  if (checkIfTenantAllowed) {
    const links = {
      name: "Approvals",
      url: "/softwareRequestsList",
      key: "key4",
    };
    navLinkGroups[0].links.push(links);
  }

  const getKeyByURL = (): string => {
    const {
      location: { pathname },
    } = props;
    switch (pathname) {
      case "/":
        return "key1";
      case "/softwareUploadJobManagement":
        return "key2";
      case "/promoteSoftware":
        return "key3";
      case "/softwareRequestsList":
        return "key4";

      default:
        return "key1";
    }
  };

  function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
    props.history.replace(item?.url);
    ev?.stopPropagation();
    ev?.preventDefault();
  }

  const getUserName = () => {
    const rawIdToken = sessionStorage.getItem("msal.idtoken");
    if (rawIdToken) {
      const decodedToken = JSON.parse(atob(rawIdToken.split(".")[1]));
      // console.log("The decoded token is : ", decodedToken);
      console.log(decodedToken);
      return decodedToken.name;
    }
    return "User";
  };

  const handleLogout = () => {
    sessionStorage.clear();
    props.history.replace("/logout");
  };

  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    shouldFocusOnContainer: true,
    items: [{ key: "rename", text: "Logout", onClick: () => handleLogout() }],
  }));

  return (
    <div>
      <div className="app-header">
        <span className="app-heading">DM update upload tool</span>
        <DefaultButton
          className="username"
          text={getUserName()}
          menuProps={menuProps}
          persistMenu
        />
      </div>
      <div className="nav-bar-container">
        <Nav
          selectedKey={getKeyByURL()}
          groups={navLinkGroups}
          className="nav-bar"
          // styles={navStyles}
          onLinkClick={_onLinkClick}
        />
        <div className="app-container">{props.children}</div>
      </div>
    </div>
  );
}

export default withRouter(WithNavigation);