import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import WithNavigation from './common/navBar/navBar';
import FirmwareUpdate from './pages/FirmwareUpdate/firmware-update';
import ListSoftwareRequests from './pages/ListSoftwareRequests/list-software-requests';
import Logout from './pages/Logout';
import PromoteSoftware from './pages/PromoteSoftware/promote-software';
import SoftwareUploadJobManagement from './pages/SoftwareUploadJobManagement/software-upload-job-management';

function App() {
  return (
    <Router>
        <Switch>
          <Route exact path='/' render={() => <WithNavigation><FirmwareUpdate /></WithNavigation>} />
          <Route exact path='/softwareUploadJobManagement' render={() => <WithNavigation><SoftwareUploadJobManagement /></WithNavigation>}/>
          <Route exact path='/promoteSoftware' render={() => <WithNavigation><PromoteSoftware /></WithNavigation>}/>
          <Route exact path='/softwareRequestsList' render={() => <WithNavigation><ListSoftwareRequests /></WithNavigation>}/>
          <Route exact path='/logout' render={() => <Logout />} />
        </Switch>
    </Router>
  );
}

export default App;
