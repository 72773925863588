import { DefaultButton } from '@fluentui/react';

const Logout = () => (
    <div>
        You have been successfully logged out. Click here to login again
        <div>
            <DefaultButton text='Login' onClick={() => window.location.href = '/'} />
        </div>
    </div>
)

export default Logout;