export const tableHeaderOptions = [
  'Device Type',
  'Manufacturer',
  'Model',
  'Component Type',
  'Version Name',
  'Version Code',
  'DogFood',
  'MSIT',
  'TAP',
  'PROD',
];

export const tableHeaderMap: { [key: string]: string } = {
  deviceType: 'Device Type',
  manufacturer: 'Manufacturer',
  model: 'Model',
  componentType: 'Component Type',
  versionName: 'Version Name',
  versionCode: 'Version Code',
};

export const ringTableHeaderMap: { [key: string]: string } = {
  dogFood: 'DogFood',
  msit: 'MSIT',
  tap: 'TAP',
  prod: 'PROD',
};

export enum currentStatus {
  active = 'Active',
  inActive = 'Inactive',
}