import { custom, map, object, primitive, serializable, SKIP } from "serializr";

export class DependencyInfo {
  @serializable(primitive())
  public firmware: string | undefined;

  @serializable(primitive())
  public companyportal: string | undefined;

  @serializable(primitive())
  public oem: string | undefined;
}

export class ReleaseStageEnvironment {
  @serializable(primitive())
  public publishedBy: string | undefined;

  @serializable(primitive())
  public publishDate: string | undefined;

  @serializable(primitive())
  public current: boolean | undefined;
}

export class ReleaseStages {
  @serializable(custom(value => value?.name, () => SKIP))
  public DogFood: ReleaseStageEnvironment | undefined;

  @serializable(custom(value => value?.name, () => SKIP))
  public Test: ReleaseStageEnvironment | undefined;
}

export class UploadRequestModel {
  @serializable(primitive())
  public deviceType: string | undefined | number;

  @serializable(primitive())
  public model: string | undefined;

  @serializable(primitive())
  public manufacturer: string | undefined;

  @serializable(primitive())
  public componentType: string | undefined | number;

  @serializable(primitive())
  public fileName: string | undefined;

  @serializable(primitive())
  public versionCode: string | undefined;

  @serializable(primitive())
  public versionName: string | undefined;

  @serializable(primitive())
  public fileUrl: string | undefined;

  @serializable(primitive())
  public digestUrl: string | undefined;

  @serializable(primitive())
  public releaseNotes: string | undefined;

  @serializable(primitive())
  public description: string | undefined;

  @serializable(object(DependencyInfo))
  public dependencyInfo: DependencyInfo | undefined;

  @serializable(object(ReleaseStages))
  public releaseStages: ReleaseStages | undefined;

  @serializable(primitive())
  public path: string | undefined;
}

export class UploadResponseModel {
  @serializable(primitive())
  public deviceType: string | undefined | number;

  @serializable(primitive())
  public releaseStage: string | undefined | number;

  @serializable(primitive())
  public model: string | undefined;

  @serializable(primitive())
  public manufacturer: string | undefined;

  @serializable(primitive())
  public minFirmwareVer: string | undefined;

  @serializable(primitive())
  public minCompanyPortalVer: string | undefined;

  @serializable(primitive())
  public minOemVer: string | undefined;

  @serializable(primitive())
  public componentType: string | undefined | number;

  @serializable(primitive())
  public fileName: string | undefined;

  @serializable(primitive())
  public versionCode: string | undefined;

  @serializable(primitive())
  public versionName: string | undefined;

  @serializable(primitive())
  public fileUrl: string | undefined;

  @serializable(primitive())
  public releaseNotes: string | undefined;

  @serializable(primitive())
  public description: string | undefined;
}
