import { primitive, serializable } from 'serializr';

export class SoftwareResponse {
  @serializable(primitive())
  public fileName: string | undefined | number;

  @serializable(primitive())
  public softwareId: string | undefined;

  @serializable(primitive())
  public id: string | undefined | number;

  @serializable(primitive())
  public model: string | undefined;

  @serializable(primitive())
  public manufacturer: string | undefined;

  @serializable(primitive())
  public componentType: string | undefined;

  @serializable(primitive())
  public versionCode: string | undefined;

  @serializable(primitive())
  public releaseStage: string | undefined;

  @serializable(primitive())
  public status: string | undefined | number;

  @serializable(primitive())
  public createdBy: string | undefined;

  @serializable(primitive())
  public createdByUserName: string | undefined;

  @serializable(primitive())
  public modifiedByUserName: string | undefined | null;

  @serializable(primitive())
  public errorMessage: string | undefined | null;
}

interface BaseInfo {
  id: string;
  createdBy: string;
  modifiedBy: string;
  createdByUserName: string;
  modifiedByUserName: null | string;
  createdAt: string;
  modifiedAt: null | string;
}

export interface ErrorInfo{
  errorCode: string;
  errorMessage: string;
}
export interface SoftwareRequest {
  baseInfo: BaseInfo;
  softwareId: string;
  requestType: string;
  requestPayload: string;
  requestStatus: string;
  errorInfo: ErrorInfo;
}

export interface ListSoftwareRequests {
  items: SoftwareRequest[];
  continuationToken: string | null;
}
